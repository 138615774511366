import { DateTime } from 'luxon'
import { formatRemovingCountryCode } from '@/common/modules/formatters'

// String A includes String B; case insensitive.
export function filterMatch (a = '', b = '') {
  return a.toLowerCase().includes(b.toLocaleLowerCase())
}

//Voicemail Contact Helpers
export const phoneNumberNotKnown = (phone_number) =>{
  return [
    'anonymous',
    'asterisk',
    'private',
    'restricted',
    'unavailable',
    'unknown',
  ].includes(phone_number.toLowerCase())
}

export const contactNameOrPhoneNumber = (people, phone_number, negative_return_value, isNotAccountOwner) => {
  if (isNotAccountOwner) {
    return "*******"
  }

  const contactPhoneNumber = phone_number.replace(/\D/g,'')
  const name = people.find(p => p.person_phones.find(p => p.phone_number === contactPhoneNumber))
  if (typeof name !== 'undefined') {
    return `${name.first_name}`
  }

  return negative_return_value
}

const numberFormatter = (number) => {
  if (typeof number === "object") {
    return number.map(num => numberFormatter(num)).join(', ')
  }

  return number.split('').map((each, key) => {
    return key === 1? each + ' (' : key === 4 ? each + ') ': key === 7? each + '-': each
  }).join('')
}

//Voicemail Contact Helpers for SMS room
export const setContactsForRooms = (people, rooms) => {
  for (var i = 0; i < rooms.length; i++) {
    if(rooms[i].room_name_override) {
      continue
    }

    // Splits the phone number for each room to make it more readable
    // Example: from +12345678900 to => +1(234)567-8900
    let roomName = numberFormatter(rooms[i].roomName)
    let roomUsers = rooms[i].users

    // Group room
    if(roomName.includes(',')) {
      rooms[i].roomName = roomName.split(', ').map(subName => contactNameOrPhoneNumber(people, subName.substring(2), subName))
      rooms[i].roomName = rooms[i].roomName.sort((a, b) => groupNameSort(people, a, b)).join(', ')
    } else {
      rooms[i].roomName = contactNameOrPhoneNumber(people, roomName.substring(2), roomName)
    }

    if(roomUsers.length > 0) {
      for (var x = 0; x < roomUsers.length; x++) {
        rooms[i].users[x].username = contactNameOrPhoneNumber(people, formatRemovingCountryCode(roomUsers[x].username), roomUsers[x].username)
      }
    }
  }
  return rooms
}

export const nameIsAContact = (people, name) => {
  return people.filter(p => p.first_name === name).length >= 1
}

// For group messages, we sort with contact names first sorted alphabetically by first name, then numbers and lastly 'You' for the phone user.
export const groupNameSort = (people, a, b) => {
  if(nameIsAContact(people, a) && nameIsAContact(people, b)) {
    return a.localeCompare(b)
  } else if(nameIsAContact(people, a)) {
    return -1
  } else if(nameIsAContact(people, b)) {
    return 1
  } else {
    return 0
  }
}

// Compliance service helpers
export const yearsSinceDue = (complianceEvent) => {
  const dueDate = complianceEvent.due_date || complianceEvent.dueDate
  let yearsSinceDue = 0

  if (dueDate) {
    const yearsUntilDue = DateTime
      .fromFormat(dueDate, 'yyyy-MM-dd')
      .diffNow('years').values.years

    yearsSinceDue = yearsUntilDue * -1
  }

  return yearsSinceDue
}

// Date helpers
export const startOfToday = () => DateTime.local().startOf('day')
export const fromIso = input => DateTime.fromISO(input)
export const daysSince = input => fromIso(input).diffNow('days').values.days
export const isOverAYearOld = input => daysSince(input) <= -365
export const isBeforeStartOfToday = input => fromIso(input).startOf('day') <= startOfToday()

// Define luxon format 'Sat, Dec 10'. Usage: fromIso(input).toLocaleString(dayMonthDayFormat).
export const dayMonthDayFormat = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
}

// Sort array of objects by key using localeCompare, case-insensitive
export const sortArrayByKey = (arr, key = 'name') => {
  if (!arr.every(obj => obj.hasOwnProperty(key))) {
    return arr
  }

  return [...arr].sort((a, b) => {
    return a[key].localeCompare(b[key], undefined, { sensitivity: 'base' })
  })
}

