import * as t from '../mutations'
import http from '@/http'
import axios from 'axios'

const STATE = {
  vehicles: [],
  makes: [],
  models: [],
  vinInfo: [],
  vehicleProducts: [],
  currentVehicle: null,
  vehicleOrgPlates: [],
  vehicleFormData: {},
  vehicleEntityTypes: [],
  vehicleRegistrationProducts: [],
  vehicleSimpleProducts: {},
  sponsorPlateImageBaseUrl: 'https://corporate-tools-sponsor-plate-images.s3.us-west-2.amazonaws.com/',
}

const GETTERS = {
  vehicles: state => state.vehicles,
  makes: state => state.makes,
  models: state => state.models,
  vinInfo: state => state.vinInfo,
  vehicleProducts: state => state.vehicleProducts,
  currentVehicle: state => state.currentVehicle,
  vehicleOrgPlates: state => state.vehicleOrgPlates,
  vehicleFormData: state => state.vehicleFormData,
  vehicleEntityTypes: state => state.vehicleEntityTypes,
  vehicleRegistrationProducts: state => state.vehicleRegistrationProducts,
  vehicleSimpleProducts: state => state.vehicleSimpleProducts,
  sponsorPlateImageBaseUrl: state => state.sponsorPlateImageBaseUrl,
}
const ACTIONS = {
  async getModels({ commit }, { make }) {
    try {
      const response = await axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake/${make}?format=json`)
      commit(
        t.VEHICLE_MODELS,
        response.data.Results.map(result => result.Model_Name.toUpperCase()).sort()
      )
    } catch (error) {
      console.error(error)
    }
  },

  async getVinInfo({ commit }, { vin }) {
    try {
      const response = await axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValues/${vin}?format=json`)
      commit(
        t.VEHICLE_VIN_INFO,
        response.data.Results?.at(0)
      )
    } catch (error) {
      console.error(error)
    }
  },

  async create({ dispatch }, { vehicle }) {
    try {
      await http.post(`client/vehicle_registrations`, { vehicle_registration: vehicle })
      await dispatch('loadVehicles')
    } catch (error) {
      console.error(error)
    }
  },

  async addRenewal({ dispatch }, { id }) {
    try {
      await http.post(`client/vehicle_registrations/add_renewal/${id}`)
      await dispatch('loadVehicles')
    } catch (error) {
      console.error(error)
    }
  },

  async endRenewal({ dispatch }, { id }) {
    try {
      await http.post(`client/vehicle_registrations/end_renewal/${id}`)
      await dispatch('loadVehicles')
    } catch (error) {
      console.error(error)
    }
  },

  async update({ dispatch }, { vehicle }) {
    try {
      await http.put(`client/vehicle_registrations/${vehicle.id}`, { vehicle_registration: vehicle })
      await dispatch('loadVehicles')
    } catch (error) {
      console.error(error)
    }
  },

  async removeVehicleRegistration({ dispatch }, { id }) {
    try {
      await http.post(`client/vehicle_registrations/remove_vehicle_registration/${id}`)
      await dispatch('loadVehicles')
    } catch (error) {
      console.error(error)
    }
  },

  async loadVehicles({ commit }) {
    try {
      const response = await http.get(`client/vehicle_registrations`)
      commit(t.VEHICLES_LOADED, response.data.result)
    } catch (error) {
      console.error(error)
    }
  },

  async findMake({ commit }, { make }) {
    try {
      const response = await axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/GetVehicleTypesForMake/${make}?format=json`)
      commit(t.VEHICLE_MAKES, response.data.result)
    } catch (error) {
      console.error(error)
    }
  },

  async getMakes({ commit }, { vehicle_type }) {
    switch (vehicle_type) {
      case 'light_vehicle':
        vehicle_type = 'car'
        break
      case 'heavy_truck':
        vehicle_type = 'truck'
        break
      case 'motorcycle':
        vehicle_type = 'moto'
        break
      case 'recreational_vehicle':
        vehicle_type = 'bus'
        break
      case 'cargo_trailer':  case 'travel_trailer':
        vehicle_type = 'trailer'
        break
      default:
        return vehicle_type
    }

    try {
      const response = await axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/GetMakesForVehicleType/${vehicle_type}?format=json`)
      commit(
        t.VEHICLE_MAKES,
        response.data.Results.map(result => result.MakeName).sort()
      )
    } catch (error) {
      console.error(error)
    }
  },
  async loadProducts ({ commit, getters }) {
    try {
      const response = await http.get(`client/vehicle_registrations/get_products/${getters.currentVehicle.vehicle.id}`)
      commit(t.VEHICLE_PRODUCTS, response.data.result)
    } catch (error) {
      console.error(error)
    }
  },

  setCurrentVehicle({ commit }, vehicle) {
    commit(t.CURRENT_VEHICLE, vehicle)
  },

  async loadOrgPlates({ commit, dispatch }) {
    try {
      const response = await http.get(`client/vehicle_registrations/load_org_plates`)
      commit(t.VEHICLE_ORG_PLATES, response.data.result)
      dispatch('loadOrgPlateImages')
    } catch (error) {
      console.error(error)
    }
  },

  loadOrgPlateImages({ commit, getters }) {
    const plates = getters.vehicleOrgPlates

    plates.forEach(plate => {
      const imageUrl = `${getters.sponsorPlateImageBaseUrl}${plate.id}-preview.png`
      const img = new Image()
      img.src = imageUrl

      img.onload = () => {
        plate.image = img.src
      }
    })

    commit(t.VEHICLE_ORG_PLATES, plates)
  },

  async getVehicleFormData({ commit, getters }, filingMethodId) {
    try {
      const response = await http.get(`client/vehicle_registrations/get_form_data/${getters.currentVehicle.vehicle.id}/${filingMethodId}`)
      commit(t.VEHICLE_FORM_DATA, response.data.result.data)
    } catch (error) {
      console.error(error)
    }
  },

  async loadVehicleRegistrationProducts({ commit }) {
    try {
      const response = await http.get(`client/vehicle_registrations/get_vehicle_registration_products`)
      commit(t.VEHICLE_REGISTRATION_ENTITY_TYPES, response.data.result.entity_types)
      commit(t.VEHICLE_REGISTRATION_PRODUCTS, response.data.result.products)
      commit(t.VEHICLE_REGISTRATION_SIMPLE_PRODUCTS, response.data.result.categorized_simple_products)
    } catch (error) {
      console.error(error)
    }
  },

  async getVehicleRegistrationFee(_, vehicleId) {
    try {
      const response = await http.get(`client/vehicle_registrations/get_vehicle_registration_fee/${vehicleId}`)
      return response.data.result.data
    } catch (error) {
      console.error(error)
    }
  },
}

const MUTATIONS = {
  [t.VEHICLES_LOADED](state, vehicles) {
    state.vehicles = vehicles
  },
  [t.VEHICLE_MAKES](state, makes) {
    state.makes = makes
  },
  [t.VEHICLE_MODELS](state, models) {
    state.models = models
  },
  [t.VEHICLE_VIN_INFO](state, vinInfo) {
    state.vinInfo = vinInfo
  },
  [t.VEHICLE_PRODUCTS](state, products) {
    state.vehicleProducts = products
  },
  [t.VEHICLE_ORG_PLATES](state, products) {
    state.vehicleOrgPlates = products
  },
  [t.CURRENT_VEHICLE](state, vehicle) {
    state.currentVehicle = vehicle
  },
  [t.VEHICLE_FORM_DATA](state, vehicleFormData) {
    state.vehicleFormData = vehicleFormData
  },
  [t.VEHICLE_REGISTRATION_ENTITY_TYPES](state, vehicleEntityTypes) {
    state.vehicleEntityTypes = vehicleEntityTypes
  },
  [t.VEHICLE_REGISTRATION_PRODUCTS](state, vehicleProducts) {
    state.vehicleRegistrationProducts = vehicleProducts
  },
  [t.VEHICLE_REGISTRATION_SIMPLE_PRODUCTS](state, vehicleSimpleProducts) {
    state.vehicleSimpleProducts = vehicleSimpleProducts
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
